import Keycloak from "keycloak-js"; // Import KeycloakInstance
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthUserSlice } from "./types";
import { IAuthUser, UserRole } from "../../interfaces/authUser";
import { IAuthTokens } from "../../api";

export const authUserInitialState: IAuthUserSlice = {
  user: {} as IAuthUser,
  verifier: "",
  licenseKey: "",
  impersonate: { roleCode: "", isActive: false, roleName: "" },
  roles: {
    list: [] as UserRole[],
    id: -1,
  },
  keycloak: {} as Keycloak,
};

export const authUserSlice = createSlice({
  name: "authUser",
  initialState: authUserInitialState,
  reducers: {
    loginUser: (state: IAuthUserSlice, { payload }: PayloadAction<IAuthUser>) => {
      state.user = { ...payload, role: [...payload.role] };
    },
    logoutUser: (state: IAuthUserSlice) => {
      state.user = {} as IAuthUser;
      state.verifier = "";
    },
    // updateCurrentUser: (state: UserSlice, { payload }: PayloadAction<IEditUserResponseData>) => {
    //   state.user = { ...state.user, ...payload };
    // },
    setVerifier: (state: IAuthUserSlice, { payload }: PayloadAction<string>) => {
      state.verifier = payload;
    },
    setLicenseKey: (state: IAuthUserSlice, { payload }: PayloadAction<string>) => {
      state.licenseKey = payload;
    },
    setKeycloakInstance: (state: IAuthUserSlice, { payload }: PayloadAction<Keycloak | null>) => {
      // Update the state to include the Keycloak instance
      state.keycloak = payload;
    },
    setKeyTokens: (state: IAuthUserSlice, {payload}: PayloadAction<IAuthTokens> ) => {
      state.keycloak.accessToken = payload.accessToken;
      state.keycloak.refreshToken = payload.refreshToken;
      state.keycloak.idToken = payload.idToken;
    }
  },
});

export const { loginUser, logoutUser, setVerifier, setLicenseKey, setKeycloakInstance, setKeyTokens } = authUserSlice.actions;

export default authUserSlice.reducer;
