import { useEffect, useState } from "react";
import { PdfViewer } from "../../components";
import "./document-container.scss";
import { Badge, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { PointContainer } from "./components/point-container/PointContainer";
import { documentService } from "../../api";
import { SECTION, openNotificationWithIcon } from "../../utils";
import { Tooltip } from "antd/lib";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDocumentReview } from "../../pages/Document";

export const DocumentContainer = () => {
  const [pdfViewer, setPdfViewer] = useState({ signedUrl: "", currentPage: 1 });
  const [activeTab, setActiveTab] = useState("1");
  const [activeSection,setActiveSection] = useState("default");
  const { statistics, setCurrentPoint, documentReview } = useDocumentReview();
  
  useEffect(() => {
    const fetchSingedUrlByPath = async () => {
      try {
        const { signedUrl } = await documentService.getSignedUrlByFilepath(
          documentReview.fileName
        );
        handlePdfChange("signedUrl", signedUrl);
      } catch (error: any) {
        console.log(error);
        openNotificationWithIcon("", error.response.data.message, "error");
      }
    };
    fetchSingedUrlByPath();
  }, []);

  const handlePdfChange = (key: string, value: string | number) =>
    setPdfViewer((prev) => ({ ...prev, [key]: value }));

  const handleTabChange = (newTab: string) => {
    setCurrentPoint(documentReview.points.find(point => +newTab === point.point))
    setActiveTab(newTab)
  };

  const handleRightClick = () => {
    if (activeSection === SECTION.PDF || activeSection === SECTION.DEFAULT) {
      setActiveSection(SECTION.PDF);
    } else {
      setActiveSection(SECTION.DEFAULT);
    }
  };

  const handleLeftClick = () => {
    if (activeSection === SECTION.TAB || activeSection === SECTION.DEFAULT) {
      setActiveSection(SECTION.TAB);
    } else {
      setActiveSection(SECTION.DEFAULT);
    }
  };

  return (
    <div className={`document-page-container ${activeSection === SECTION.TAB ? 'full-tab' : ''}`}>
      {(activeSection === "pdf" || activeSection === "default") && <PdfViewer
        currPage={pdfViewer.currentPage}
        pdf={pdfViewer.signedUrl}
        displayPagination
        pageInfo={{
          width: activeSection === SECTION.PDF ? 1100 : undefined
        }}
        noExpand={activeSection === SECTION.PDF}
        setCurrPage={(pageNum) => handlePdfChange("currentPage", pageNum)}
        className={activeSection === SECTION.PDF ? "full-pdf expanded" : "pdf-viewer"}
      />}
       <div className={`drawer ${activeSection === SECTION.TAB ? "tab-drawer" : activeSection === SECTION.PDF ? "pdf-drawer" : ""}`}>
          <div className="arrow-icons">
             {(activeSection === SECTION.TAB || activeSection === SECTION.DEFAULT) && (
              <RightOutlined className="right-arrow" onClick={handleRightClick} />
            )}
            {(activeSection === SECTION.PDF || activeSection === SECTION.DEFAULT) && (
              <LeftOutlined className="left-arrow" onClick={handleLeftClick} />
            )}
          </div>
        </div>
      {(activeSection === SECTION.TAB || activeSection === SECTION.DEFAULT) && <Tabs
        activeKey={activeTab}
        defaultActiveKey="1"
        onChange={handleTabChange}
        tabBarGutter={25}
        size="large"
        className={`point-tabs-container ${activeSection === SECTION.TAB ? "full-tab" : ""}`}        
      >
        {documentReview.points.map((currPoint: DocumentReview) => (
          <TabPane
            tab={
              <span className="point-title">
                <span>{currPoint.displayName} </span>
                {statistics[currPoint.displayName]?.badge ? 
                  <Tooltip title="Number of non-compliant hits and comments"><Badge
                  count={statistics[currPoint.displayName].badge}
                  overflowCount={20}
                  color="red"
                  showZero={true}
                  className="non-compliant-badge"
                /></Tooltip> : ''
                }
              </span>
            }
            key={currPoint.point}
          >
            {activeTab === String(currPoint.point) && (
              <PointContainer
                pointData={currPoint}
                currentPageNum={pdfViewer.currentPage}
                setCurrentPageNum={(pageNum) =>
                  handlePdfChange("currentPage", pageNum)
                }
                documentUuid={documentReview.documentUuid}
              />
            )}
          </TabPane>
        ))}
      </Tabs>}
    </div>
  );
};

interface Props {
  document: DocumentData;
}
