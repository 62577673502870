import React, { FormEvent, useEffect, useState } from "react";
import "./case-settings-container.scss";
import { Anchor, Button } from "antd";
import { openNotificationWithIcon } from "../../../../utils";
import { Loading } from "../../../../components";

const CaseSettings = () => {
  const [loading, setLoading] = useState({ formSubmit: false, callFlagDetails: true });
  const [isEditing, setIsEditing] = useState({ callFlagDetails: false });

  const handleToggleEdit = (formName: keyof typeof isEditing) =>
    setIsEditing((prev) => ({ ...prev, [formName]: !prev[formName] }));
  
  const handleFormOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading((prev) => ({ ...prev, formSubmit: true }));
    try {
      handleToggleEdit('callFlagDetails')
      openNotificationWithIcon("", "Settings saved", "success");
    } catch (error) {
      openNotificationWithIcon("", "Failed updating settings", "error");
    } finally {
      setLoading((prev) => ({ ...prev, formSubmit: false }));
    }
  };

  const fetchExistingCallFlagDetails = async () => {
    try {
      setLoading((prev) => ({ ...prev, callFlagDetails: false }));
    } catch (error) {
      openNotificationWithIcon("", "Failed to fetch existing call flag details", "error");
    }
  };

  const handleCancel = () => {
    setIsEditing((prev) => ({ ...prev, callFlagDetails: false }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {}

  useEffect(() => {
    fetchExistingCallFlagDetails();
  }, []);

  const renderCallFlagDetails = () => {
    if(loading.callFlagDetails) {
      return <Loading/>
    }

    return <form className="case-settings" id="additional-info" onSubmit={handleFormOnSubmit}>
    <div className="input-container">
      <h4>Auth History</h4>
      <textarea
        name="auth"
        id="authHistory"
        rows={4}
        disabled={!isEditing.callFlagDetails}
        onChange={handleOnChange}
        value={""}
      ></textarea>
    </div>
    <div className="input-container">
      <h4>Claims History</h4>
      <textarea
        name="claim"
        id="claimsHistory"
        rows={4}
        disabled={!isEditing.callFlagDetails}
        onChange={handleOnChange}
        value={ ""}
      ></textarea>
    </div>
    <div className="input-container">
      <h4>Call History</h4>
      <textarea
        name="call"
        id="callHistory"
        rows={4}
        disabled={!isEditing.callFlagDetails}
        onChange={handleOnChange}
        value={""}
      ></textarea>
    </div>
    <div className="case-settings-actions">
      {isEditing.callFlagDetails ? (
        <>
          <Button className="fill" onClick={handleFormOnSubmit} disabled={loading.formSubmit}>
            {loading.formSubmit ? "Saving" : "Save"}
          </Button>
          <Button className="outline" disabled={loading.formSubmit} onClick={handleCancel}>
            Cancel
          </Button>
        </>
      ) : (
        <Button className="fill" onClick={() => handleToggleEdit("callFlagDetails")}>
          Edit
        </Button>
      )}
    </div>
  </form>
  }

  return (
    <div className="case-settings-container">
      <Anchor
        affix={false}
        items={[
          {
            key: "1",
            href: "#additional-info",
            title: "Additional Info Settings",
          },
        ]}
      />
      {renderCallFlagDetails()}
    </div>
  );
};

export default CaseSettings;
