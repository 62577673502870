import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MODAL_TYPE } from "../../utils";
import './modal.scss'
import ExpandPdf from "./expand-pdf";

export const Modal = () => {
  const { modal } = useSelector((state: RootState) => state.ui);
  
  return (
    <>
      {modal[MODAL_TYPE.EXPAND_PDF] && <ExpandPdf />}
    </>
  );
};
