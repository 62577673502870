import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthUser } from "../hooks";
import { PAGE_URL } from "../utils";
import { Spin, SpinProps } from "antd";

export const GuestLayout = (props: SpinProps) => {
  const navigate = useNavigate();
  const { accessToken, roleCode } = useAuthUser();

  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      navigate(PAGE_URL.DOCUMENTS);
    }
  }, [accessToken, navigate, roleCode]);

  return (
    <div className="flex aic jcc h-full p-10">
      <Spin tip={"Loading..."} {...props} />
      <Outlet />
    </div>
  );
};
