import axios, { AxiosRequestHeaders } from "axios";
import { store } from "../App";
import { logoutUser } from "../store";
import { PAGE_URL } from "../utils";
import { ActiveDirectoryService } from "./activeDirectoryAuth";
import qs from 'qs';

export const authHeaders = (headers: any, token: string): any => {
  const namespace = "authorization";
  const privateNamespace = "x-user-token";
  headers[namespace] = `Bearer ${token}`;
  headers[privateNamespace] = "true";
  return headers;
};

export const withAuthHeaders = (headers: any): any => {
  const token = store.getState().authUser.user.accessToken;
  return authHeaders(headers, token || "");
};

const defaultOptions = {
  baseURL: window._env_.VITE_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

const authOptions = {
  baseURL: window._env_.VITE_APP_API_BASE_URL_AUTHENTICATION,
  headers: {
    "Content-Type": "application/json",
  },
};

const apiClient = axios.create(defaultOptions);
const authApiClient = axios.create(authOptions)

apiClient.interceptors.request.use(
  (config: any) => {
    config.headers = withAuthHeaders({}) as AxiosRequestHeaders;
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (res) => res,
  async (error: any) => {
    // handle user session refresh here
    const pathname = window.location.pathname;
    if (pathname !== PAGE_URL.LOGIN && error.response.status === 401) {
      const keycloak = store.getState().authUser.keycloak;
      const keycloakLogoutUrl =  `${window._env_.VITE_APP_KEYCLOAK_BASE_URL}realms/${keycloak.realm}/protocol/openid-connect/logout`
      + '?id_token_hint=' + keycloak.idToken
      + '&post_logout_redirect_uri=' + encodeURIComponent(window.location.origin);
      window.location.replace(keycloakLogoutUrl);
    }
    if (error.response.status >= 500) {
      error.response.message = "Something went wrong!";
    }
    if (error.response.status !== 401) {
      error.response.message = "";
    }
    return Promise.reject(error);
  }
);

export { apiClient, authApiClient, qs};
