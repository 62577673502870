import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

declare global {
  interface ImportMeta {
    env: {
      VITE_APP_API_BASE_URL: string;
      VITE_APP_ML_SERVER_BASE_URL: string;
      VITE_APP_ANNOTATION_STUDIO_BASE_URL: string;
      VITE_APP_API_BASE_URL_AUTHENTICATION: string;
      [key: string]: any;
    };
  }
  interface Window {
    _env_: {
      VITE_APP_API_BASE_URL: string;
      VITE_APP_ML_SERVER_BASE_URL: string;
      VITE_APP_ANNOTATION_STUDIO_BASE_URL: string;
      VITE_APP_API_BASE_URL_AUTHENTICATION: string;
      [key: string]: any;
    };
  }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
