import { authApiClient } from "./apiClient";

interface IUserSingleDirectoryLoginResponse {
  data: {
    authCodeUrl: string;
    verifier: string;
  };
  status: string;
}

interface IAccount {
  username: string;
  name: string;
  accessToken: string;
  tokenType: string;
  expiresOn: string;
  userId: string;
}

interface IRole {
  id: number;
  name: string;
}

interface IGetActiveDirectoryUserResponse {
  account: IAccount;
  groups: string[];
  role: IRole;
}

interface IGetActiveDirectoryUserResponseWrapper {
  data: IGetActiveDirectoryUserResponse;
  status: string;
}

interface IActiveDirectoryLogoutResponse {
  data: {
    logoutUri: string;
  };
  status: string;
}

export interface IAuthTokens {
  accessToken: string;
  refreshToken: string;
  idToken: string;
}

const activeDirectoryLogin = async (redirectURI: string, successRedirectURI: string) => {
  const res = await authApiClient.post<IUserSingleDirectoryLoginResponse>("/auth/login", {
    redirectURI,
    successRedirectURI,
  });
  return res;
};

const activeDirectoryAcquireToken = async (
  code: string,
  redirectURI: string,
  codeVerifier: string
) => {
  const res = await authApiClient.post<IGetActiveDirectoryUserResponseWrapper>("/auth/requestToken", {
    code,
    redirectURI,
    codeVerifier,
  });
  return res;
};
const activeDirectoryLogout = async (logoutURI: string) => {
  const res = await authApiClient.post<IActiveDirectoryLogoutResponse>("/auth/logout", {
    logoutURI,
  });
  return res;
};

export const ActiveDirectoryService = {
  activeDirectoryLogin,
  activeDirectoryAcquireToken,
  activeDirectoryLogout,
};
