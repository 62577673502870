import { Button } from "antd";
import "./point-container.scss";
import { useEffect, useState } from "react";
import { SourcesTab } from "../sources-tab/SourcesTab";
import { RationaleTab } from "../rationale-tab/RationaleTab";
import {
  DOCUMENT_INSTANCE_TYPES,
  DOCUMENT_TAB_ACTIONS,
  DocumentInstanceTypes,
  DocumentTabAction,
  openNotificationWithIcon,
} from "../../../../utils";
import { SingleInstance } from "../single-instance/SingleInstance";
import { Comments } from "../comments/Comments";
import { UpsertComment } from "../upsert-comment/UpsertComment";
import { Loading } from "../../../../components";
import { documentService } from "../../../../api";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useDocumentReview } from "../../../../pages/Document";

const initialIsLoadingState = { tab: false, exportingAnalysis: false };

export const PointContainer = ({ pointData, currentPageNum, documentUuid, setCurrentPageNum }: Props) => {
  const [activeTab, setActiveTab] = useState<DocumentInstanceTypes>(DOCUMENT_INSTANCE_TYPES.NONCOMPLIANT);
  const [tabContent, setTabContent] = useState<DocumentTabAction>(DOCUMENT_TAB_ACTIONS.DEFAULT);
  const [currInstance, setCurrInstance] = useState<Instance | null>(null);
  const [currentComment, setCurrentComment] = useState<PageComment | undefined>(undefined);
  const [definitionClicked,setdefinitionClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(initialIsLoadingState);
  const [pointStatistics, setPointStatistics] = useState({ compliant: 0, nonCompliant: 0 });
  const { statistics } = useDocumentReview();
  
  useEffect(() => {
    const stats = { compliant: 0, nonCompliant: 0 };
    pointData.instances.forEach(instance => instance.model_label.toLowerCase() === DOCUMENT_INSTANCE_TYPES.COMPLIANT.toLowerCase() ? stats.compliant++ : stats.nonCompliant++ )
    setPointStatistics({...pointStatistics, ...stats });
  }, [ pointData.point ])
  

  const handleTabChange = (newTab?: DocumentInstanceTypes) => {
    if (activeTab === newTab) return null;
    setCurrInstance(null);
    setTabContent(DOCUMENT_TAB_ACTIONS.DEFAULT);
    newTab && setActiveTab(newTab);
  };

  const handleClickInstanceActions = (
    activeTab: DocumentTabAction,
    currentInstance?: Instance,
    comment?: PageComment
  ) => {
    currentInstance && setCurrInstance(currentInstance);
    setTabContent(activeTab);
    setCurrentComment(comment);
  };

  const handleSetIsLoading = (key: keyof typeof initialIsLoadingState) => {
    setIsLoading((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleClickExportAnalysis = async () => {
    handleSetIsLoading("exportingAnalysis");
    try {
      const res = await documentService.exportAnalysis([ documentUuid ]);
      const blob = await res.blob();
      var file = window.URL.createObjectURL(blob);
      window.location.assign(file); // TODO : add filename as doc name
      openNotificationWithIcon("", "Export Analysis downloaded successfully", "success");
    } catch (error: any) {
      console.log(error);
      openNotificationWithIcon("", error?.response.data.message || error?.message, "error");
    } finally {
      handleSetIsLoading("exportingAnalysis");
    }
  };

  const renderInstances = (instances: Instance[], allPages: boolean = false) => {
    return (
      <>
        <div className="instances-container">
          <div className="instances scrollbar-hide">
            {instances.map((tempInstance, idx) => (
              <SingleInstance
                title={`Passage ${idx + 1}`}
                key={tempInstance.instance_chunk_uuid}
                onClickAction={handleClickInstanceActions}
                instance={tempInstance}
                className={"card-bd-grey"}
              />
            ))}
            { activeTab === DOCUMENT_INSTANCE_TYPES.COMMENTS && <Comments currentPage={currentPageNum} handleUpsertComment={handleClickInstanceActions} pointNumber={pointData.point} documentUuid={documentUuid} allPages={allPages} setCommentStat={setPointStatistics}/> }
          </div>
          <footer className="actions">
            <Button
              className="fill"
              onClick={() => handleClickInstanceActions(DOCUMENT_TAB_ACTIONS.COMMENTS, undefined, undefined)}
            >
              Add Comment
            </Button>
            <Button className="fill" onClick={handleClickExportAnalysis} loading={isLoading.exportingAnalysis}>
              Export Analysis
            </Button>
          </footer>
        </div>
      </>
    );
  };

  const handleDefinitionClick = () =>{
    setdefinitionClicked(!definitionClicked);
  }

  const renderTabData = () => {
    if (isLoading.tab) return <Loading />;
    switch (tabContent) {
      case DOCUMENT_TAB_ACTIONS.DEFAULT:
        const filteredData = pointData.instances.filter(
          (currentInstance) => currentInstance?.model_label === activeTab
        );
        return (
          <div className="default-tab">
            {renderInstances(filteredData, activeTab === DOCUMENT_INSTANCE_TYPES.COMMENTS)}
          </div>
        );
      case DOCUMENT_TAB_ACTIONS.SOURCES:
        return <SourcesTab instance={currInstance!} handleBack={() => handleTabChange()} setCurrentPageNum={setCurrentPageNum}/>;
      case DOCUMENT_TAB_ACTIONS.RATIONALE:
        return (
          <RationaleTab
            instance={currInstance!}
            handleBack={() => handleTabChange()}
            handleSourceAction={handleClickInstanceActions}
          />
        );
      case DOCUMENT_TAB_ACTIONS.COMMENTS:
        return (
          <UpsertComment
            currentComment={currentComment}
            currentPage={currentPageNum}
            handleBack={() => handleTabChange()}
            setLoading={() => handleSetIsLoading("tab")}
            pointNumber={pointData.point}
            documentUuid={documentUuid}
          />
        );
      default:
        openNotificationWithIcon("", "An unexpected error occured", "error");
        return null;
    }
  };

  return (
    <div className="point-container">
      <div className="point-types">
        <span
          className={activeTab === DOCUMENT_INSTANCE_TYPES.NONCOMPLIANT ? "active" : ""}
          onClick={() => handleTabChange(DOCUMENT_INSTANCE_TYPES.NONCOMPLIANT)}
        >
          Non Compliant ({pointStatistics.nonCompliant} hits)
        </span>
        <span
          className={activeTab === DOCUMENT_INSTANCE_TYPES.COMPLIANT ? "active" : ""}
          onClick={() => handleTabChange(DOCUMENT_INSTANCE_TYPES.COMPLIANT)}
        >
          Complaint ({pointStatistics.compliant} hits)
        </span>
        <span
          className={activeTab === DOCUMENT_INSTANCE_TYPES.COMMENTS ? "active" : ""}
          onClick={() => handleTabChange(DOCUMENT_INSTANCE_TYPES.COMMENTS)}
        >
          {statistics[pointData.displayName].noOfComments} Comment{statistics[pointData.displayName].noOfComments === 1 ? 's' : ''}
        </span>
      </div>
      <div className="definition">
        <span className="definition-title" onClick={handleDefinitionClick}>Definition {!definitionClicked ? <RightOutlined /> : <DownOutlined />}</span>
        {definitionClicked && (
          <div className="card-bd-grey">
            <p>{pointData.description}</p>
          </div>
        )}
      </div>
      {renderTabData()}
    </div>
  );
};

interface Props {
  pointData: DocumentReview;
  currentPageNum: number;
  documentUuid: string;
  setCurrentPageNum: (pageNum: number) => void;
}
