import React, { useEffect, useState } from "react";
import Table from "antd/lib/table";
import { FILE_STATUS, getLocalDateTimeStringFromISO, openNotificationWithIcon } from "../../utils";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { PdfViewer } from "../pdf-viewer/PdfViewer";
import "./documents.scss";
import { useNavigate } from "react-router-dom";
import { AppPagination } from "../app-pagination/AppPagination";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import { documentService } from "../../api";
import { Input } from "antd/lib";
import { SearchIcon } from "../../assets/icons";
import { Button, Checkbox } from "antd";

interface Props {
  projectId?: number;
  loading?: boolean;
}

interface IUploadedDocumentsDataType {
  key: React.Key;
  name: string;
  createdOn: string;
  updatedOn: string;
  documentUUID: string;
  totalPages: number;
  nonCompliantHits: number;
  compliantHits: number;
  nonCompliancePercentage: number;
  status: string;
}

const initialSelectRows = { displayCheckBox: false, exporting: false, rows: [] as string[] }

export const Documents = (props: Props) => {
  const { projectId } = props;
  const navigate = useNavigate();
  const { fetchDocuments, documents, isDocumentsLoading, paginationObj, totalDocuments } = useFetchDocuments();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [fileUrl, setFileUrl] = useState("");
  const [selectRows, setSelectRows] = useState(initialSelectRows);

  const handlePreviewOnClick = async (path: string, e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    try {
      const { signedUrl } = await documentService.getSignedUrlByFilepath(path);
      setFileUrl(signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err?.message , "error");
    }
  };

  const handleClickTableRow = (docId: string, checked: boolean) => setSelectRows(prev => ({...prev, rows: checked ? [ ...prev.rows, docId] : prev.rows.filter(id => id !== docId)}))

  const handleToggleSelectRows = () => setSelectRows(prev => ({...prev, displayCheckBox: !prev.displayCheckBox}))

  const getColumns = () => [
    {
      title: "File name",
      key: "name",
      dataIndex: "name",
      render: (_: string, record: any) => (
        <span className="row-name">
          { selectRows.displayCheckBox ? 
          <Checkbox onChange={(e) => handleClickTableRow(record.documentUUID, e.target.checked)} />
        : null}
          <span onClick={() => navigate(`/document/${record.documentUUID}`)}>
            {record.name}
          </span>
        </span>
      ),
    },
    {
      title: "No. of Pages",
      key: "totalPages",
      dataIndex: "totalPages",
      sorter: (a: IUploadedDocumentsDataType, b: IUploadedDocumentsDataType) => a.totalPages - b.totalPages,
    },
    {
      title: "Non-compliant hits",
      key: "nonCompliantHits",
      dataIndex: "nonCompliantHits",
      sorter: (a: IUploadedDocumentsDataType, b: IUploadedDocumentsDataType) => a.nonCompliantHits - b.nonCompliantHits,
    },
    {
      title: "Compliant Hits",
      key: "compliantHits",
      dataIndex: "compliantHits",
      sorter: (a: IUploadedDocumentsDataType, b: IUploadedDocumentsDataType) => a.compliantHits - b.compliantHits,
    },
    {
      title: "% of Non-Compliance",
      key: "nonCompliancePercentage",
      dataIndex: "nonCompliancePercentage",
      sorter: (a: IUploadedDocumentsDataType, b: IUploadedDocumentsDataType) =>
        a.nonCompliancePercentage - b.nonCompliancePercentage,
      render: (text: any, value: any) => <>{value.nonCompliancePercentage.toFixed(2)}</>,
    },
    {
      title: "Status",
      key: "Status",
      dataIndex: "Status",
      render: (text: string, record: IUploadedDocumentsDataType) => { 
        return (
          <span className={`status status-${record.status.toLowerCase()}`}>
            {record.status}
          </span>
        );
      },
    },
    {
      title: "Preview",
      render: (text: any, value: any) => (
        <span className="dashboard-table-preview" onClick={(e) => handlePreviewOnClick(value.name, e)}>
          <EyeOutlined className="dashboard-table-preview-icon" title="Preview document" />
        </span>
      ),
    },
  ];

  useEffect(() => {
    fetchDocuments();
  }, [projectId, page, pageSize]);

  const filteredData = documents.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const data: IUploadedDocumentsDataType[] = filteredData
    .filter((item) => item.name !== "")
    .map((item, index) => ({
      updatedOn: getLocalDateTimeStringFromISO(item.updatedAt),
      createdOn: getLocalDateTimeStringFromISO(item.createdAt),
      name: item.name,
      nonCompliantHits: item.documentReview.nonCompliantHits,
      compliantHits: item.documentReview.compliantHits,
      nonCompliancePercentage: (item.documentReview.nonCompliantHits * 100) / (item.documentReview.nonCompliantHits + item.documentReview.compliantHits),
      key: index,
      documentUUID: item.uuid,
      totalPages: item.documentReview.noOfPages,
      status: item.documentReview.noOfComments > 0 ? FILE_STATUS.REVIEWED : FILE_STATUS.NOT_REVIEWED
    }));

    const renderExportAnalysisButton = () => {
      if(!selectRows.displayCheckBox) {
        return <Button className="fill" onClick={handleToggleSelectRows}>Bulk Export</Button>
      }
      const handleExportAnalysis = async () => {
        if(!selectRows.rows.length) { return openNotificationWithIcon('', "No documents selected", 'info') }
        setSelectRows(prev => ({...prev, exporting: true}));
        try {
          const res = await documentService.exportAnalysis(selectRows.rows);
          const blob = await res.blob();
          var file = window.URL.createObjectURL(blob);
          window.location.assign(file); // TODO : add filename as doc name
          setSelectRows(initialSelectRows);
          openNotificationWithIcon('', "Analysis Exported Successfully", 'success')
        } catch (error) {
          openNotificationWithIcon('', "Error exporting analysis", 'error')
          console.log(error)
        } finally {
          setSelectRows(prev => ({...prev, exporting: false }));
        }
      }

      return <><Button className="fill" onClick={handleExportAnalysis} loading={selectRows.exporting}>Export Analysis</Button><Button className="outline" onClick={() => setSelectRows(initialSelectRows)}>Cancel</Button></>
    }

  return (
    <div className="dashboard-table">
      <div className="table-actions">
        <div className="search-document">
          <Input placeholder="Search within records" onChange={(e) => setSearchTerm(e.target.value.trim())} />
          <SearchIcon fill="grey" className="search-icon" width={15} />
        </div>
        <div className="header-actions">
        {renderExportAnalysisButton()}
        <AppPagination
          className="tr paginationDiv"
          {...{
            showSizeChanger: true,
            current: page,
            total: totalDocuments,
            pageSize,
            pageSizeOptions,
            onChange: onPaginationChange,
          }}
        />
        </div>
      </div>
      <div className="dashboard-table__content">
        <Table columns={getColumns()} pagination={false} dataSource={data} loading={isDocumentsLoading} rowKey={record => record.documentUUID} />
        {fileUrl && (
          <div className="pdf-viewer dashboard-table__pdf-viewer">
            <PdfViewer pdf={fileUrl} displayPagination setCurrPage={setCurrentPage} currPage={currentPage} />
            <CloseOutlined className="pdf-viewer-closeicon" onClick={() => setFileUrl("")} />
          </div>
        )}
      </div>
    </div>
  );
};
