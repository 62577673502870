import React, { useState } from "react";
import { DocumentsContainer, PageWrapper } from "../../containers";

export const Documents = () => {
  const [isLoading, setIsLoading] = useState(false);

  return <PageWrapper loading={isLoading} pageContainerChildren={<DocumentsContainer />} />;
};

export default Documents;
