import { useEffect, useState } from "react";
import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import "./comments.scss";
import {
  DOCUMENT_TAB_ACTIONS,
  DocumentTabAction,
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../../../utils";
import { useAuthUser } from "../../../../hooks";
import { commentService } from "../../../../api/comment";
import { Loading } from "../../../../components";
import { useDocumentReview } from "../../../../pages/Document";

export const Comments = ({ currentPage, handleUpsertComment, documentUuid, pointNumber, allPages = false }: Props) => {
  const [comments, setComments] = useState<PageComment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { firstName, lastName } = useAuthUser();
  const { setStatistics, currentPoint } = useDocumentReview();

  const fetchCommentsByPage = async () => {
    setIsLoading(true);
    try {
      const data = await commentService.getAll({ pageNumber: allPages ? undefined : currentPage, documentUuid, pointNumber });
      setComments(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCommentsByPage();
  }, [currentPage, allPages]);

  const handleClickDelete = async (reqObj: { username: string; id: number }) => {
    setIsLoading(true);
    try {
      await commentService.deleteById(reqObj);
      openNotificationWithIcon("", "Comment deleted successfully", "success");
      fetchCommentsByPage();
      setStatistics((prev) => ({...prev, [currentPoint?.displayName!]: { ...prev[currentPoint?.displayName!], badge: prev[currentPoint?.displayName!].badge - 1, noOfComments: prev[currentPoint?.displayName!].noOfComments - 1 }}))
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderComments = () => {
    if (isLoading) return <Loading />;
    return (
      <div className="comments-content">
        {!comments?.length ? <p>No comments.</p> : ""}
        {comments.map((comment) => (
          <div className="single-comment" key={comment.id}>
            <div className="heading">
              <h5>{comment.createdBy}</h5>
              <h5>Page No: {comment.pageNumber}</h5>
            </div>
            <div className="comment-details">
              <span className="date-time">{getLocalDateTimeStringFromISO(comment.createdAt, false)}</span>
              <div className="content">
                <p>{comment.comment}</p>
                <div className="actions">
                  {comment.createdBy === `${firstName} ${lastName}` ? (
                    <div className="actions">
                      <EditIcon
                        onClick={() => handleUpsertComment(DOCUMENT_TAB_ACTIONS.COMMENTS, undefined, comment)}
                        fill="$darkBlue"
                      />
                      <DeleteIcon
                        onClick={() => handleClickDelete({ id: comment.id, username: comment.createdBy })}
                        fill="$darkBlue"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="comments card-bd-grey">
      <h4>User Comments</h4>
      {renderComments()}
    </div>
  );
};

interface Props {
  currentPage: number;
  handleUpsertComment: (activeTab: DocumentTabAction, instance?: Instance, comment?: PageComment) => void;
  documentUuid: string;
  pointNumber: number;
  allPages?: boolean
  setCommentStat: Function
}
