import { useForm, SubmitHandler } from "react-hook-form";
import { Form, Button, Select } from "antd";

import "./upsert-comment.scss";
import { useAuthUser } from "../../../../hooks";
import { openNotificationWithIcon } from "../../../../utils";
import { SetStateAction, useState } from "react";
import { commentService } from "../../../../api/comment";
import { useDocumentReview } from "../../../../pages/Document";

export const UpsertComment = ({ currentComment, currentPage, handleBack, pointNumber, documentUuid }: Props) => {
  const [ loading, setIsLoading ] = useState(false);
  const { firstName, lastName } = useAuthUser();
  const { register, handleSubmit, reset } = useForm<FormInputs>({
    defaultValues: currentComment ? { comment: currentComment?.comment } : {},
  });
  const { setStatistics, currentPoint, pdfData } = useDocumentReview();

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    if(!data.comment.trim()) return openNotificationWithIcon('', "Please enter a comment", 'info')
    setIsLoading(true);
    try {
      const dataWithUserInfo = {
        comment: data.comment,
        username: `${firstName} ${lastName}`,
        pageNumber: data.pageNumber || currentPage,
        pointNumber,
      };
      if (currentComment) {
        await commentService.update({...dataWithUserInfo, id: currentComment.id});
      } else {
        await commentService.save({...dataWithUserInfo, documentUuid });
      setStatistics((prev) => ({...prev, [currentPoint?.displayName!]: { ...prev[currentPoint?.displayName!], badge: prev[currentPoint?.displayName!].badge + 1, noOfComments: prev[currentPoint?.displayName!].noOfComments + 1 }}))
      }
      openNotificationWithIcon("", `Comment ${currentComment ? 'updated' : 'saved'} for Point ${pointNumber}`, "success");
      handleBack();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    reset();
    handleBack();
  };

  const pageOptions = [];
  for (let i = 1; i <= pdfData.totalPages; i++) {
    pageOptions.push({ value: i, label: `Page ${i}` });
  }

  return (
    <div className="upsert-comment">
      <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <Form.Item label="Page Number">
          <select {...register("pageNumber")}defaultValue={currentPage}>
            {pageOptions.map((option) => (
              <option key={option.value} value={option.value} >
                {option.label}
              </option>
            ))}
          </select>
        </Form.Item>
        <Form.Item label="Comment" rules={[{ required: true, message: "Please enter a comment" }]}>
          <textarea
            className="ant-input css-dev-only-do-not-override-mzwlov ant-input-outlined"
            {...register("comment")}
            id="comment"
            rows={10}
          ></textarea>
        </Form.Item>
        <Form.Item className="actions">
          <Button className="fill" htmlType="submit" loading={loading}>
            Save Comment
          </Button>
          <Button className="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

interface Props {
  currentComment?: PageComment;
  currentPage: number;
  handleBack: VoidFunction;
  setLoading: SetStateAction<Object>;
  pointNumber: number;
  documentUuid: string;
}

type FormInputs = {
  comment: string;
  pageNumber: number;
};
