import { Spin, SpinProps } from "antd";
import React from "react";

export const Loading = (props: SpinProps) => {
  return (
    <div className="flex aic jcc h-full p-10">
      <Spin tip={"Loading..."} {...props} />
    </div>
  );
};
