import { apiClient, withAuthHeaders, qs } from "./apiClient";

const getDocuments = async (reqObj: any): Promise<IDocumentsListRes> => {
  const { data } = await apiClient.get(`/file`, {
    params: reqObj
  });
  return data;
};

const getDocumentByUuid = async (documentUuid: string): Promise<DocumentData> => {
  const { data } = await apiClient.get(`/file/document-review/${documentUuid}`);
  return data.data;
};

const getSignedUrlByFilepath = async (path: string) => {
  const { data } = await apiClient.get("/storage/signedUrl/readByName", {
    params: { path },
  });
  return data.data;
};

const getOcr = async (rebObj: { fileUuid: string; pageNum: number }) => {
  const { data } = await apiClient.post("/file/getocr", rebObj);
  return data.data;
};

const exportAnalysis = async (documentUuids: string[]) => {
  const query = qs.stringify({ documentUuids })
  const url = `${window._env_.VITE_APP_API_BASE_URL}/file/export-analysis?${query}`;
  const options = {
    headers: {
      Authorization: withAuthHeaders({}),
    },
  };
  return await fetch(url, options)
};

const updateStatus = async (uuid:string, status: string = '') =>{
  const { data } = await apiClient.put(`/instance/feedback-status/${uuid}`, { status });
  return data.data;
}

export const documentService = {
  getSignedUrlByFilepath,
  getDocuments,
  getDocumentByUuid,
  getOcr,
  exportAnalysis,
  updateStatus
};
