import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { AppHeader, AppMenu, PageHeader } from "../containers";
import { useAuthUser } from "../hooks";
import { PAGE_URL } from "../utils";

const initialPageHeader = { pageTitle: "", displayBackButton: false, pageRoute: "" }

export const PrivateLayout = () => {
  const { accessToken } = useAuthUser();
  if (!accessToken) window.location.replace(PAGE_URL.LOGIN);

  const [pageHeader, setPageHeader] = useState<PageHeader>(initialPageHeader);
  
  const resetPageHeader = () => {
    setPageHeader(initialPageHeader)
  };

  return (
    <Layout>
      <AppMenu />
      <Layout>
        <AppHeader pageHeader={pageHeader} setPageHeader={setPageHeader} />
        <Outlet context={{ pageHeader, setPageHeader, resetPageHeader }} />
        {/* <AppFooter /> */}
      </Layout>
    </Layout>
  );
};

export interface IOutletContext {
  setPageHeader: (header: PageHeader) => void;
  pageHeader: PageHeader;
  resetPageHeader: () => void;
}
