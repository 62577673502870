import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser, logoutUser, setKeycloakInstance } from "../../store";
import { PAGE_URL } from "../../utils";
import { message } from "antd";
import Keycloak from "keycloak-js";

const keycloakJson = {
  url: window._env_.VITE_APP_KEYCLOAK_BASE_URL,
  clientId: window._env_.VITE_APP_KEYCLOAK_CLIENT_ID,
  realm: window._env_.VITE_APP_KEYCLOAK_REALM,
};

function Login() {
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
  const [authenticated, setAuthenticated] = useState(false);
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  useEffect(() => {
    const getData: (response: Keycloak) => void = async (response) => {
      if (response.authenticated && response.idTokenParsed) {
        const { given_name, family_name, email } = response.idTokenParsed;
        const realmName: string = response.realm || "";
        dispatch(
          loginUser({
            accessToken: response.idToken || "",
            id: 0,
            firstName: given_name,
            lastName: family_name,
            email,
            role: [],
            orgId: 0,
            realmName: realmName,
            clientId: 0,
            roles: {
              list: [],
              id: 0,
            },
          })
        );
        navigate(PAGE_URL.DOCUMENTS);
      } else {
        message.error("Email or password is incorrect");
      }
    };
    const kc: Keycloak = new Keycloak(keycloakJson as Keycloak.KeycloakConfig);
    const doLogin: () => void = kc.login;
    const initKeyclaok = async () => {
      const isAuthenticated = await kc.init({
        onLoad: "check-sso",
        checkLoginIframe: false,
      });
      console.log('kc.init')
      if (isAuthenticated) {
        // setKeycloak(kc);
        // setAuthenticated(isAuthenticated);
        dispatch(setKeycloakInstance(kc));
        getData(kc);
        console.log('authenticated');
      } else {
        console.log("Not authenticated");
        doLogin();
      }
    };
    initKeyclaok();
  }, []);

  return <></>;
}

export default Login;
