import { BackButton } from "../../../../components";
import { DOCUMENT_TAB_ACTIONS } from "../../../../utils";
import { SingleInstance } from "../single-instance/SingleInstance";
import "./rationale-tab.scss";

export const RationaleTab = ({
  instance,
  handleBack,
  handleSourceAction,
}: Props) => {
  return (
    <div className="rationale-tab card-bd-grey">
      <BackButton onClick={handleBack} />
      <SingleInstance
        instance={instance}
        onClickAction={() =>
          handleSourceAction(DOCUMENT_TAB_ACTIONS.SOURCES, instance)
        }
        tab={DOCUMENT_TAB_ACTIONS.RATIONALE}
      />
      <div className="relationale-content">
        <h5>Rationale</h5>
        <p className="format-string">{instance.model_explanation}</p>
      </div>
    </div>
  );
};

interface Props {
  instance: Instance;
  handleBack: () => void;
  handleSourceAction: Function;
}
