import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./back-button.scss";

export const BackArrow = ({ onClick }: { onClick?: VoidFunction}) => {
  const navigate = useNavigate();
  const handleClick = () => onClick ? onClick() : navigate(-1)
  return <LeftOutlined onClick={handleClick} />;
};

interface Props {
  className?: string;
  onClick?: VoidFunction;
}

export const BackButton = ({ className, onClick }: Props) => {
  const navigate = useNavigate();
  const handleClick = () => onClick ? onClick() : navigate(-1);

  return (
    <div className={`back-icon ${className}`} onClick={handleClick}>
      <a>
        <LeftOutlined />
        <span>Back</span>
      </a>
    </div>
  );
};
