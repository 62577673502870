import "./sources-tab.scss";
import { BackButton, Loading } from "../../../../components";
import { useEffect, useMemo, useState } from "react";
import { documentService } from "../../../../api";

const isLoadingInitialState = { loadingOcr: false };

export const SourcesTab = ({ instance, handleBack, setCurrentPageNum }: Props) => {
  const [isLoading, setIsLoading] = useState(isLoadingInitialState);
  const [ocr, setOcr] = useState<string>("No OCR Text available\n");

  useEffect(() => {
    setIsLoading((prev) => ({ ...prev, loadingOcr: true }));
    setCurrentPageNum(instance.sources.page_num);
    const fetchOcr = async () => {
      try {
        const data = await documentService.getOcr({
          fileUuid: instance.sources.document_uuid,
          pageNum: instance.sources.page_num,
        });
        if (data.ocr_text) setOcr(data.ocr_text);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prev) => ({ ...prev, loadingOcr: false }));
      }
    };
    fetchOcr();
  }, [instance]);

  const highlightedSourceContent = useMemo(() => {
    let content = ocr;
    const { end, begin } = instance.sources;
    const term = ocr.substring(begin ? begin : 0, end ? end : 0);
    const html = `<span class="bg-yellow">${term}</span>`;
    content = content.replace(term, html);
    content = content.replaceAll(`\n`, `<br/>`);
    return content;
  }, [ instance, ocr ]);

  return (
    <div className="sources-tab card-bg-gray">
      <div className="sources-tab__actions">
        <BackButton onClick={handleBack} /> <h5>Page No: {instance.sources.page_num}</h5>
      </div>
      {isLoading.loadingOcr ? <Loading /> : <div dangerouslySetInnerHTML={{ __html: highlightedSourceContent }}></div>}
    </div>
  );
};

interface Props {
  instance: Instance;
  handleBack: () => void;
  setCurrentPageNum: (pageNum: number) => void;
}
