export enum THEME {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum STATUS_CODE {
  FORBIDDEN = 403,
}

export enum MODAL_TYPE {
  EDIT_PROFILE = "editProfile",
  FORGOT_PASSWORD = "forgotPassword",
  ADD_AND_SIGN_UP_USER = "addAndSignUpUser",
  LICENSE_KEY = "licenseKey",
  EXPAND_PDF = "expandPdf",
}

export enum USER_ROLES_CODE {
  ADMIN = "SUPER_ADMIN",
  DATA_SCIENTIST = "DATA_SCIENTIST",
  BUSINESS_USER = "BUSINESS_USER",
}

export const DOCUMENT_TAB_ACTIONS = {
  DEFAULT: "DEFAULT",
  SOURCES: "SOURCES",
  RATIONALE: "RATIONALE",
  COMMENTS: "COMMENTS"
} as const;

export const DOCUMENT_INSTANCE_TYPES = {
  COMPLIANT: "Compliant",
  NONCOMPLIANT: "Non-Compliant",
  COMMENTS: "Comments"
} as const;

export type DocumentInstanceTypes = "Compliant" | "Non-Compliant" | "Comments";
export type DocumentTabAction = keyof typeof DOCUMENT_TAB_ACTIONS;

export const FEEDBACK_STATUS = {
  NOT_REVIEWED: "NOT REVIEWED",
  AGREE: "AGREE",
  DISAGREE: "DISAGREE"
} as const;

export const SECTION = {
  PDF: "pdf",
  TAB: "tab",
  DEFAULT: "default"
} as const;

export const FILE_STATUS = {
  REVIEWED: "REVIEWED",
  NOT_REVIEWED: "NOT REVIEWED",
} as const;

