import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "./single-instance.scss";
import { Button } from "antd";
import {
  DOCUMENT_TAB_ACTIONS,
  DocumentTabAction,
  FEEDBACK_STATUS,
  NotificationType,
  openNotificationWithIcon,
} from "../../../../utils";
import { useState } from "react";
import { documentService } from "../../../../api";
import { Tooltip } from "antd/lib";

export const SingleInstance = ({ instance, title, onClickAction, tab, className }: Props) => {
  const [feedbackStatus, setFeedbackStatus] = useState(instance.feedbackStatus);

  const handleSubmitFeedback = async (newStatus: FeedbackStatus) => {
    if(feedbackStatus !== FEEDBACK_STATUS.NOT_REVIEWED && newStatus !== feedbackStatus) return null;
    if(newStatus === feedbackStatus) newStatus = FEEDBACK_STATUS.NOT_REVIEWED;

    try {
      await documentService.updateStatus(instance.instance_chunk_uuid, newStatus === FEEDBACK_STATUS.NOT_REVIEWED ? '' : newStatus);
      setFeedbackStatus(newStatus);
      instance.feedbackStatus = newStatus;
      let notificationMessage = "";
      let notificationType = "info";

      switch (newStatus) {
        case FEEDBACK_STATUS.DISAGREE:
          notificationMessage = "Disagree";
          notificationType = "error";
          break;
        case FEEDBACK_STATUS.AGREE:
          notificationMessage = "Agree";
          notificationType = "success";
          break;
        default:
          notificationMessage = "Status Reset";
          break;
      }

      openNotificationWithIcon("", notificationMessage, notificationType as NotificationType);
    } catch (error) {
      openNotificationWithIcon("", "Error updating the status", "error");
    }
  };

  const renderRationaleButton = () => {
    if (tab === DOCUMENT_TAB_ACTIONS.RATIONALE) return null;
    return (
      <Button size="small" className="outline" onClick={() => onClickAction(DOCUMENT_TAB_ACTIONS.RATIONALE, instance)}>
        Rationale
      </Button>
    );
  };

  return (
    <div key={instance.instance_chunk_uuid} className={`single-instance ${className}`}>
      <div className="single-instance-content">
        <h4>{title ? title : "Passage"}</h4>
        <p>{instance.content}</p>
        <div className="routes">
          <Button
            size="small"
            className="outline"
            onClick={() => onClickAction(DOCUMENT_TAB_ACTIONS.SOURCES, instance)}
          >
            Source
          </Button>
          {renderRationaleButton()}
        </div>
      </div>
      <div className="single-instance__actions">
        <span className="icons">
          <Tooltip title={feedbackStatus === FEEDBACK_STATUS.NOT_REVIEWED ? "Agree" : ""}>
            <CheckCircleOutlined
              className={`icon icon-approve ${feedbackStatus === FEEDBACK_STATUS.AGREE ? "approved" : ""}`}
              onClick={() => handleSubmitFeedback(FEEDBACK_STATUS.AGREE)}
            />
          </Tooltip>
          <Tooltip title={feedbackStatus === FEEDBACK_STATUS.NOT_REVIEWED ? "Disagree" : ""}>
            <CloseCircleOutlined
              className={`icon icon-reject ${feedbackStatus === FEEDBACK_STATUS.DISAGREE ? "rejected" : ""}`}
              onClick={() => handleSubmitFeedback(FEEDBACK_STATUS.DISAGREE)}
            />
          </Tooltip>
        </span>
        <h5>Page Num: {instance.sources.page_num}</h5>
      </div>
    </div>
  );
};

interface Props {
  instance: Instance;
  onClickAction: (activeTab: DocumentTabAction, instance: Instance) => void;
  tab?: DocumentTabAction;
  className?: string;
  title?: string;
}
